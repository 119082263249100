import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../language.service';
import { Translate } from '../translate';
import { Patterns } from '../patterns';
import { ContactUs } from '../contact-us';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ServerAnswer } from '../server-answer';
import { UserService } from '../user.service';
import {MessageService} from 'primeng/api';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  translate: Translate;
  patterns: Patterns;
  sendEmailForm: FormGroup;
  contactUs: ContactUs;
  answer: ServerAnswer;
  reCaptchaVerify = false;
  reCaptchaResponse: string;

  constructor(private languageService: LanguageService, private userService: UserService, private messageService: MessageService) {
    this.translate = this.languageService.loadLanguage();
    this.answer = new ServerAnswer();
    this.patterns = new Patterns();
    this.sendEmailForm = new FormGroup({
      subject: new FormControl('', [Validators.required, Validators.pattern(this.patterns.subject)]),
      message: new FormControl('', [Validators.required, Validators.pattern(this.patterns.description)]),
      replyEmail: new FormControl('', [Validators.required, Validators.pattern(this.patterns.email)])
    });
  }

  ngOnInit(): void {
    if (sessionStorage.getItem('readDataForm') === 'true') {
      this.contactUs = new ContactUs(sessionStorage.getItem('subject'), sessionStorage.getItem('message'), sessionStorage.getItem('message'));
      sessionStorage.setItem('subject', '');
      sessionStorage.setItem('message', '');
      sessionStorage.setItem('replyEmail', '');
      sessionStorage.setItem('readDataForm', '');
    }
  }

  sendEmail(): void {
    const subject = this.sendEmailForm.get('subject').value;
    const message = this.sendEmailForm.get('message').value;
    const replyEmail = this.sendEmailForm.get('replyEmail').value;
    this.contactUs = new ContactUs(subject, message, replyEmail);
    this.userService.sendEmailByContactUs(this.contactUs, this.reCaptchaResponse).subscribe(
      (response: HttpResponse<any>) => {
        this.answer = this.userService.switchAnswer(response.body);
        this.onSentMail();
      },
      (error: HttpErrorResponse) => {
        this.answer = this.userService.switchAnswer(error.status);
        this.onErrorServerResponse();
      }
    );
    if (!this.sendEmailForm.valid) {
      this.sendEmailForm.markAllAsTouched();
    }
  }
  resolvedReCaptcha(captchaResponse: string): void {
    this.reCaptchaResponse = captchaResponse;
    this.reCaptchaVerify = true;
  }
  onSentMail(): void {
    this.sendEmailForm.reset();
    this.answer.serverAnswer = null;
    this.reCaptchaVerify = false;
    this.messageService.add({severity: 'success', summary: this.answer.subject, detail: this.answer.message, life: 10000});
  }
  onErrorServerResponse(): void {
    sessionStorage.setItem('readDataForm', 'true');
    this.answer.serverAnswer = null;
    this.messageService.add({severity: 'error', summary: this.answer.subject, detail: this.answer.message, life: 10000});
  }

}
