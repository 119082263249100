import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CurriculumVitaeComponent } from './curriculum-vitae/curriculum-vitae.component';
import { ProjectsComponent } from './projects/projects.component';
import { ServicesComponent } from './services/services.component';
import { CertyficateComponent } from './certyficate/certyficate.component';
import { RewerdComponent } from './rewerd/rewerd.component';
import { CustomerPanelComponent } from './customer-panel/customer-panel.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: ServicesComponent},
  { path: 'projects', component: ProjectsComponent},
  { path: 'contactus', component: ContactUsComponent},
  { path: 'customerpanel', component: CustomerPanelComponent},
  { path: 'cv', component: CurriculumVitaeComponent},
  { path: 'certificate', component: CertyficateComponent},
  { path: 'rewerd', component: RewerdComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
