import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../language.service';
import { Translate } from '../translate';
import {UserService} from '../user.service';
import {Patterns} from '../patterns';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  translate: Translate;

  constructor(private languageService: LanguageService, private userService: UserService) {
    this.translate = this.languageService.loadLanguage();
   }

  ngOnInit(): void {
    this.userService.logVisit(Patterns.SERVICES_PATH);
  }

}
