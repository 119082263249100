import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rewerd',
  templateUrl: './rewerd.component.html',
  styleUrls: ['./rewerd.component.scss']
})
export class RewerdComponent implements OnInit {

  pdfSrc = '/assets/pdf/rewerd.pdf';

  constructor() { }

  ngOnInit(): void {
  }

}
