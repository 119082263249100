import { Translate } from './translate';

export function getTranslateEn(): Translate {
  return {
    home: {
      cookiesMessage: 'Pages use cookies. By Clicking \"X\" or by clicking into any content on this site, you agree to allow cookies to be placed.'
    },
    services: {
      header: 'Programming services and deployment applications.',
      description: `<p>I offer comprehensively service for programming and deployment application on selected Your domain name.</p>
      <ul>
      <li>Web applications, which  working with internet browser and uses internet connections:
      <ul>
      <li>Booking applications - use to reservation visits, appointments and promotion Your company, business  or institution.</li>
      <li>Sales applications - use to online buying and promotion Your company</li>
      <li>Or every other applications, which I can made on Your individual order.</li>
      </ul>
      </li>
      <li>Desktop application - which installs on office computers.</li>
      </ul>
      <p>Every applications use database to purpose persist data Your company or institution.<p/>
      <p>I also offer adjusting to changing external conditions Your have got applications.</p>
      <p>For made or adjusting application, I provide administration.</p>
      <p>Prepare to use applications You can browse on \"Projects\" part this page.</p>`
    },
    projects: {
      header: 'Applications prepare to check out. On order, every project can be fit to individual requirement.',
      civilworkoffers: {
        name: 'Civil work offers and cost calculation',
        description: `
        <div>Application support civil work company with prepare offers and calculations for their customers which asked.
        Application is use also for Your company promotion in internet.
        Thanks to the fact customer can see company profile, services, made projects and references.
        On internet pages company, you can publish information about current events.</div>
        <div>Application support customer relationship enable register customer and provide directly access to all offer request and offers (bids).</div>
        <div>Main application features for customer account:</div>
        <ul>
        <li>create patient account by myself</li>
        <li>sign in by application form or social services like Facebook and Google</li>
        <li>create offer request for planned civil works with expected completed date. Is possibility append project files like technical drawings, drafts, photos</li>
        <li>explore answers on offer requests (bids) with calculations - calculation can be print to PDF format</li>
        <li>explore offer request history and details with comments and appended files</li>
        <li>delete account with remove all offer request and all appended files</li>
        </ul>`,
        descriptionMoreHeader: 'More',
        descriptionMoreContent: `
        <b>Main application features for employee account:</b>
        <ul>
        <li>offer request searching with date range and customer data</li>
        <li>create offer request on behalf customer (e.g. based on emails from customer)</li>
        <li>create answer for offer request (bid) use interface similar to MS Word or LibreOffice Writer enable easy edit text, create tables, lists, et cetera</li>
        <li>create offer calculations, which will be attach to bid. Calculation can be create from scratch or can be copy from existing calculations base created by another employees for share work</li>
        <li>prepare calculation with possibility search calculation items in existing calculation base. Total cost in calculation is a running value.
        New type of civil work or measure units are automatically add to database. Calculation item can be add on end, insert or move.</li>
        <li>calculation service provide handle type of civil works, measure units and provide remove calculation created by ourselves</li>
        <li>employee can create customer account when the customer not found in database</li>
        <li>in case create offer request by myself customer, one of employee will be random assign to this offer request</li>
        <li>employees gets email notifications to oncoming date of make offer request answer (bid)</li>
        </ul>
        <div>Application also use administrators and owner.</div>
        <div>Administrator can create patient, doctor and employee account, activate or deactivate them and remove customer account.
        Administrator can also assign employee to offer request.</div>
        <div>Substantial feature administrators account is possibility edit internet pages like Main Page, About Us, Portfolio, References, Services using interface similar to MS Word or LibreOffice Writer.</div>
        <div>Application owner is only one and can create administrator or employees account with assign them role and activate or deactivate them.</div>
        <div>Users like administrator and employee can assign additional role - e.g. employee can be parallel in administrator role.</div>
        <div>Every application user can update your personal data.</div>
        <div>All users except owner, can add or remove your photo, which will be display for others users.</div>
        <div>Application is prepare in two languages PL and EN. - change after shift browser language. Add another languages is a possibility.</div>
        <div>Project made in Java Spring technology provide safe and productive application working.</div>
        <div>To test application in role customer, pleas register and login. To test in another role, pleas send me mail with selected role - then I will create new account and application automatically send You confirmation with login data.</div>
        `
      },
      medicalclinic: {
        name: 'Medical Clinic',
        description: `
        <div>Booking application to online reserve visit terms in medical clinic.
        Provide visit handle by doctor with make diagnosis and referrals and also provide storage patient medical documentation.
        Application is use to promotion Your clinic in internet. Thank the fact patients can to know clinic profile, to meet hired doctors and check actually doctors agenda.</div>
        <div>Main application feature for patient account:</div>
        <ul>
        <li>create patient account by myself</li>
        <li>sign in by application form or social services like Facebook and Google</li>
        <li>schedule visit term to selected doctor with possibility describe ailments</li>
        <li>cancel visit term</li>
        <li>confirmation created account, planned visit term or canceled, password reset with email notification</li>
        <li>double mail remainder of oncoming visit term</li>
        <li>append and delete medical documentation files with possibility share it to only doctors</li>
        <li>explore visit history with details (diagnosis, referrals, doctors, dates)</li>
        <li>delete account with remove all visit history and all medical documentation</li>
        </ul>`,
        descriptionMoreHeader: 'More',
        descriptionMoreContent: `
        <b>Main application features for doctor account:</b>
        <ul>
        <li>make diagnosis and referrals for selected visit</li>
        <li>explore enabled patient medical documentation</li>
        <li>create planned visit sheet in selected date range</li>
        <li>print diagnosis, referrals and visit sheets to PDF format</li>
        <li>update agenda – working days and hours in week range</li>
        <li>update personal data</li>
        </ul>
        <div>Application also use employees, administrators and owner.</div>
        <div>Employees/Assistants can create patient account and reserve visit terms or cancel them on behalf patient (e.g. by phone).
        Employees can also confirm planned visits (for doctor information) and explore visit history selected patient.</div>
        <div>Administrator can create patient, doctor and employee account, activate or deactivate them and remove patient account.
        Administrator can also add and remove clinic and doctor free days.</div>
        <div>Application owner is only one and can create administrator or employees account with assign them role and also activate or deactivate them.
        Owner has possibility by myself insert or update clinic business and addresses information (without programmer).</div>
        <div>Users like administrator and employee can assign additional role e.g. employee can be parallel in administrator role.</div>
        <div>Every application user can update your personal data.</div>
        <div>All users except owner, can add or remove your photo, which will be display for others users.</div>
        <div>Project made in Bootstrap and Java Spring technology.
        Bootstrap provide use application in mobile phones, tablets as well laptop and desktop computers.
        Java Spring technology provide safe and productive application working.</div>
        <div>Application is prepare in two language PL and EN. - change after shift browser language. Add another languages is a possibility.</div>
        <div>To test application in role patient, pleas register and login.
        To test in another role, pleas send me mail with selected role - then I will create new account and application automatically send You confirmation with login data.</div>`
      },
      chat: {
        name: 'Chat application',
        description: `<div>Application provide quick (on-line) message comunication customers with employees your company. You can use this as standalone application or both with main application e.g. Medical Clinic.
        In this case, Medical Clinic employees database is in use for this chat application.</div>
        <div>Chat app can use three types of users: customers, employees and administrators. Necessary sign in to chat app for users provide certainty that everyone send message on its own behalf.
        Customers can send message to logged in (on-line) employees or off-line employees.
        Sent and received messages there are display and save in database for read next time.</div>`,
        descriptionMoreHeader: 'More',
        descriptionMoreContent: `<div>Employees can send message to logged in (on-line) customers or off-line customers.
        All conversation is on-line save to database with full access for own employee messages.
        Employees can also send message to all customers regardless their status on-line or off-line.
        Administrators can read mesages every user. Administrators also can remove all messages selected user or delete account selected user with his all conversation.</div>
        <div>Project made in RWD (Flex Layout) and Java Spring technology. RWD provide use application in mobile phones, tablets as well laptop and desktop computers.
        Java Spring technology provide safe and productive application working.</div>
        <div>Application is prepare in two language PL and EN. - change after shift browser language. Add another languages is a possibility.</div>
        <div>To test application in role customer, pleas register and login.
        To test in another role, pleas send me mail with selected role - then I will create new account and application automatically send You confirmation with login data.</div>`
      },
      baseapp: {
        name: 'Base application',
        description: `<div>The base application - has bas modules like administration (user access permissions), file module (upload, display and download file), send emails, activity schedule (time trigger), multi-language.</div>
        <div>Any feature may be developed on this base for your business or institution.</div>
        <div>Modules already exist also can be developed or change. Components appearance may loosely edit.</div>
        <p>Click \"Check it out\" to open this base application with example implementation of small part internet shop. After click \"Show photos\" you can browse screens with features and descriptions.</p>
        <p>For test purpose please call me and I give you login data.</p>`,
        descriptionMoreHeader: 'More',
        descriptionMoreContent: 'More content',
        carouselHeader: 'Base application',
        carouselDescription1: 'Users panel. On this tab you can edit data for selected user.',
        carouselDescription2: 'Assign role to user.',
        carouselDescription3: 'Assign privileges to selected role.',
        carouselDescription4: 'Reset password option. New password is random generate and send by email system directly to user. Admin does not know new password.',
        carouselDescription5: 'View panel with 2 tabs. On second tab is products table with preview product foto and you can edit product data in separate dialog window.',
        carouselDescription6: 'Display graphic file in separate dialog window.',
        carouselDescription7: 'Display PDF file in separate dialog window with many edit options.',
        carouselDescription8: 'Example of table column filter by \"zam\" word. You can filter by calendar. You see also expand table row option.'
      }
    },
    contactus: {
      firm: 'Programmer service',
      phone: 'Phone: ',
      form: 'Or fill form below and submit:',
      subject: 'Message subject:',
      text: 'Message content:',
      replyMail: 'Email to reply:',
      reCaptcha: 'reCaptcha verifying:',
      successSubject: 'Success!',
      confirmSentMail: 'Mail has ben sent correctly.'
    },
    customerPanel: {
      changePassword: 'Change email password',
      usernameOrEmail: 'User name or email:',
      currentPassword: 'Current password:',
      newPassword: 'New password:',
      repeatNewPassword: 'Repeat new password:',
      confirmChangePassword: 'Password has been changed.'
    },
    button: {
      services: 'Services',
      projects: 'Projects',
      contactus: 'ContactUs',
      sendEmail: 'Send email',
      test: 'Check it out',
      readMore: 'read more/less',
      customerPanel: 'Customer panel',
      changePassword: 'Change password',
      showPhoto: 'Show photos'
    },
    error: {
      reCaptcha: 'Verifying is necessary!',
      subject: 'Failed!',
      validSubject: 'Minimum 2 a max 75 characters without special characters',
      validMessage: 'Minimum 2 a max 400 characters without special characters',
      validReplyEmail: 'Email is not properly',
      validUsername: 'Minimum 2 a max 40 characters - allowed english alphabet letters, numbers and underscore.',
      validPassword: 'Minimum 2 a max 40 characters ',
      differentPasswords: 'Both new passwords are not the same!',
      no_connect: 'Error connection with server. Check internet connection.',
      err_400: 'Bad HTTP request.',
      err_401: 'Login or password are not correct.',
      err_403: 'You are not authorized to perform this operation.',
      err_404: 'Resource not found.',
      err_500: 'Internal server error. Pleas contact with administrator.'
    }
  } as Translate;
}

export function getTranslatePl(): Translate {
  return {
    home: {
      cookiesMessage: 'Strony używają ciasteczek. Przez kliknięcie \"X\" lub kliknięcie na linki znajdujące się na stronach wyrażasz zgodę na używanie ciasteczek.'
    },
    services: {
      header: 'Usługi programowania i wdrożenia aplikacji.',
      description: `
      <p>Oferuję kompleksowe usługi programowania aplikacji i wdrożenia na serwerze internetowym pod wybraną przez Państwo nazwą domeny.</p>
      <ul>
      <li>Aplikacje webowe działające w przeglądarce internetowej dostępne poprzez połączenie z internetem:
      <ul>
      <li>Bookingowe \u2013 wykorzystywane do rezerwacji wizyt, spotkań oraz promujące Państwa firmę bądź instytucję</li>
      <li>Sprzedażowe \u2013 wykorzystywane do zakupów poprzez internet oraz promujące Państwa firmę</li>
      <li>Lub dowolną aplikację, którą mogę opracować na indywidualne zamówienie</li>
      </ul>
      </li>
      <li>Aplikacje desktopowe, które instalowane są na komputerze biurowym.</li>
      </ul>
      <p>Każda z aplikacji, webowa lub desktopowa, korzysta z bazy danych do przechowywania informacji gromadzonych przez Państwa firmę bądź instytucję.<p/>
      <p>Oferuję także dostosowanie już posiadanych przez Państwo aplikacji do zmieniających się uwarunkowań zewnętrznych.<p/>
      <p>W ramach opracowania lub dostosowania aplikacji zajmuję się także ich administrowaniem.<p/>
      <p>Przygotowane do użycia aplikacje można przeglądać w zakładce \"Projekty\".<p/>`
    },
    projects: {
      header: 'Aplikacje przygotowane do wypróbowania. Na zlecenie, każda z aplikacji może zostać dostosowana do indywidualnych potrzeb.',
      civilworkoffers: {
        name: 'Ofertowanie i kosztorysowanie prac budowlanych',
        description: `
        <div>Aplikacja wspiera firmę budowlaną w przygotowywaniu ofert oraz kosztorysów budowlanych w odpowiedzi na zapytania swoich klientów.</div>
        <div>Aplikacja służy jednocześnie do promocji firmy w internecie dając możliwość klientom do zapoznania się z profilem działalności, oferowanymi usługami, zrealizowanymi projektami i referencjami.
        Na stronach internetowych firmy można także zamieszczać aktualne informacje o wydarzeniach w firmie.
        <div>Aplikacja wspomaga relacje z klientami umożliwiając ich rejestrację i zapewniając bezpośredni dostęp do zapytań ofertowych oraz otrzymywanych ofert i kosztorysów.</div>
        <div>Główne funkcje aplikacji dla konta klienta:</div>
        <ul>
        <li>samodzielne tworzenie konta klienta</li>
        <li>logowanie się przez formularz aplikacji lub serwisy społecznościowe takie jak Google i Facebook</li>
        <li>tworzenie zapytania ofertowego na prace budowlane z ustaleniem oczekiwanej daty odpowiedzi oraz możliwością dołączenia plików (np. szkice, projekty, zdjęcia)</li>
        <li>przeglądanie odpowiedzi na złożone zapytania (oferty) wraz z kosztorysami ofertowymi, kosztorysy drukowane są do formatu PDF</li>
        <li>przeglądanie historii oraz szczegółów złożonych zapytań ofertowych wraz z komentarzami i dołączonymi plikami</li>
        <li>usunięcia swojego konta wraz z usunięciem danych o wszystkich swoich zapytaniach ofertowych i  usunięciem wszystkich dołączonych plików</li>
        </ul>`,
        descriptionMoreHeader: 'Więcej',
        descriptionMoreContent: `
        <b>Główne funkcje aplikacji dla konta pracownika:</b>
        <ul>
        <li>wyszukiwanie zapytań ofertowych wg dat rejestracji zapytania i danych klienta</li>
        <li>tworzenie zapytania ofertowego w imieniu klienta (np. na podstawie maila od klienta)</li>
        <li>opracowywanie odpowiedzi na zapytanie ofertowe (oferty) przy użyciu interfejsu podobnego do MS Word lub LibreOffice Writer z możliwością swobodnej edycji tekstu, tworzenia tabel, list, itd.</li>
        <li>tworzenie kosztorysów ofertowych, które dołączone zostaną do oferty.
        Kosztorys może być utworzony od podstaw lub kopiowany z bazy istniejących kosztorysów tworzonych przez innych pracowników aby współdzielić się pracą</li>
        <li>opracowywanie kosztorysów z możliwością wyszukiwania pozycji kosztorysowych z kosztorysów dołączonych do innych zapytań ofertowych i tworzonych przez innych pracowników.
        Wartość kosztorysowa ogółem oraz wartości poszczególnych pozycji obliczane są na bieżąco.
        Nowe rodzaje prac budowlanych lub nowe jednostki miar dodawane są automatycznie do bazy danych z możliwością ich późniejszego wyboru.
        Pozycje kosztorysowe mogą być dodawane na koniec, wstawiane we wskazane na miejsce lub przenoszone.</li>
        <li>serwis kosztorysów umożliwia obsługę rodzajów prac budowlanych, rodzajów jednostek miar oraz umożliwia usuwanie kosztorysów opracowanych przez pracownika, który ten kosztorys utworzył</li>
        <li>pracownik ma także możliwość utworzenia nowego konta klienta jeżeli nie zostanie odnalezione podczas wyszukiwania</li>
        <li>w przypadku gdy klient złoży zapytanie ofertowe samodzielnie przez aplikację, nowo utworzone zapytanie jest przypisywane do jednego z pracowników w sposób losowy</li>
        <li>pracownicy otrzymują mailowe powiadomienia o zbliżającym się terminie przygotowania odpowiedzi na zapytanie ofertowe (oferty)</li>
        </ul>
        <div>Z aplikacji ponadto korzystają administratorzy oraz właściciel.</div>
        <div>Administratorzy mogą tworzyć konta klientów i pracowników, aktywować je lub dezaktywować oraz usuwać konta klientów. Administrator ma możliwość przypisania zapytania ofertowego do innego pracownika.</div>
        <div>Ważną funkcjonalnością administratorów jest możliwość edytowania treści Strony Głównej, O Nas, Portfolio, Referencje i Usługi używając interfejsu podobnego do MS Word lub LibreOffice Writer.</div>
        <div>Właściciel aplikacji jest tylko jeden i ma możliwość tworzenia kont administratorów lub pracowników, określenia ich roli a także ich aktywacji lub dezaktywacji.</div>
        <div>Użytkownicy tacy jak pracownik i administrator mogą także mieć przydzieloną przez właściciela dodatkową rolę \u2013 np. pracownik może być jednocześnie administratorem.</div>
        <div>Każdy z użytkowników aplikacji ma możliwość zmiany swoich danych.</div>
        <div>Wszyscy użytkownicy (za wyjątkiem właściciela) mają możliwość dodawania i usuwania swoich zdjęć, które będą widoczne dla pozostałych użytkowników.
        <div>Aplikacja została przygotowana w dwóch językach: PL i EN - zmiana następuje przy zmianie języka przeglądarki. Istnieje możliwość dodania kolejnych języków.</div>
        <div>Projekt opracowany w technologii Java Spring co zapewnia bezpieczne i szybkie działanie aplikacji.</div>
        <div>W celu przetestowania działania aplikacji w roli klienta, proszę o samodzielne przeprowadzenie rejestracji i zalogowanie się.
        Aby zalogować się jako inny użytkownik, proszę o przesłanie mailem informacji o rodzaju konta (roli) - zostanie wtedy założone nowe konto testowe a aplikacja automatycznie prześle emailem dane logowania.</div>`
      },
      medicalclinic: {
        name: 'Przychodnia lekarska',
        description: `
        <div>Aplikacja bookingowa służąca do samodzielnego (on line) umawiania wizyt w przychodni lekarskiej.
        Zapewnia obsługę wizyty przez lekarza (wystawienie diagnozy, skierowania) a także pozwala na przechowywanie dokumentacji medycznej przez pacjenta.
        Aplikacja służy jednocześnie do promocji kliniki w internecie dając możliwość pacjentom do zapoznania się z profilem działalności, poznania przyjmujących w klinice lekarzy oraz sprawdzenia aktualnego terminarza ich wizyt.</div>
        <div>Główne funkcje aplikacji dla konta pacjenta:</div>
        <ul>
        <li>samodzielne tworzenie konta pacjenta</li>
        <li>logowanie się przez formularz aplikacji lub serwisy społecznościowe takie jak Google i Facebook</li>
        <li>zaplanowanie terminu wizyty u wybranego lekarza oraz wstępne określenia dolegliwości</li>
        <li>odwołanie terminu wizyty</li>
        <li>potwierdzenie mailowe utworzenia konta, zaplanowania wizyty, jej odwołania oraz resetu hasła</li>
        <li>przypomnienie mailowe o zbliżającym się terminie wizyty – dwukrotnie</li>
        <li>dołączanie i usuwanie dokumentacji medycznej w postaci plików wraz możliwością zezwolenia na jej udostępnienie – dokumentacja może być udostępniona wyłącznie lekarzom</li>
        <li>przeglądanie historii oraz szczegółów swoich wizyt (diagnozy, skierowania, lekarze, daty)</li>
        <li>usunięcie swojego konta wraz z usunięciem danych o wszystkich swoich wizytach i usunięciem całej dołączonej dokumentacji medycznej</li>
        </ul>`,
        descriptionMoreHeader: 'Więcej',
        descriptionMoreContent: `
        <b>Główne funkcje aplikacji dla konta lekarza:</b>
        <ul>
        <li>wystawianie diagnozy oraz skierowania dla wybranej wizyty pacjenta</li>
        <li>przeglądanie udostępnionej dokumentacji medycznej pacjenta</li>
        <li>tworzenie zestawień i przeglądanie zaplanowanych wizyt w wybranym okresie</li>
        <li>drukowanie do formatu PDF diagnozy, skierowań oraz zestawienia zaplanowanych wizyt</li>
        <li>aktualizowanie terminarza swoich dni i godzin pracy w cyklu tygodniowym</li>
        <li>aktualizowania swoich danych</li>
        </ul>
        <div>Z aplikacji ponadto korzystają pracownicy i administratorzy oraz właściciel.</div>
        <div>Pracownicy/Asystenci mogą tworzyć konta pacjentów oraz mogą rezerwować terminy wizyt i je odwoływać w imieniu pacjenta (np. telefonicznie).
        Pracownicy mogą także potwierdzać zaplanowane wizyty (dla informacji lekarzy) oraz wyświetlać historię wizyt wybranego pacjenta.</div>
        <div>Administratorzy mogą tworzyć konta pacjentów, lekarzy i pracowników, aktywować je lub dezaktywować oraz usuwać konta pacjentów.
        Mogą także dodawać i usuwać dni wolne od pracy zarówno przychodni jak i lekarzy.</div>
        <div>Właściciel aplikacji jest tylko jeden i ma możliwość tworzenia kont administratorów lub pracowników, określenia ich roli a także ich aktywacji lub dezaktywacji.
        Właściciel może samodzielnie wprowadzać oraz uaktualniać dane biznesowe i adresowe przychodni.</div>
        <div>Użytkownicy tacy jak pracownik i administrator mogą także mieć przydzieloną przez właściciela dodatkową rolę \u2013 np. pracownik może być jednocześnie administratorem.</div>
        <div>Każdy z użytkowników aplikacji ma możliwość zmiany swoich danych.</div>
        <div>Wszyscy użytkownicy (za wyjątkiem właściciela) mają możliwość dodawania i usuwania swoich zdjęć, które będą widoczne dla pozostałych użytkowników.
        <div>Projekt opracowany został w technologii Bootstrap i Java Spring. Bootstrap zapewnia możliwość korzystania z aplikacji używając telefonów, tabletów, laptopów lub komputerów stacjonarnych.
        Technologia Java Spring zapewnia bezpieczne i szybkie działanie aplikacji.</div>
        <div>Aplikacja została przygotowana w dwóch językach: PL i EN – zmiana następuje przy zmianie języka przeglądarki. Istnieje możliwość dodania kolejnych języków.</div>
        <div>W celu przetestowania działania aplikacji w roli pacjenta, proszę o samodzielne przeprowadzenie rejestracji i zalogowanie się.
        Aby zalogować się jako inny użytkownik, proszę o przesłanie mailem informacji o rodzaju konta (roli) - zostanie wtedy założone nowe konto testowe a aplikacja automatycznie prześle emailem dane logowania.</div>`
      },
      chat: {
        name: 'Aplikacja do konwersacji online',
        description: `<div>Aplikacja do chatowania - szybkiego (on-line) porozumiewania się klientów z pracownikami Twojej firmy. Może być wykorzystywana jako samodzielna aplikacja
        lub jako uzupełnienie do głównej aplikacji np. Medical Clinic. W takim przypadku baza danych pracowników Medical Clinic jest wykorzystywana w aplikacji do chatowania.
        <div>Z chatu mogą korzystać trzy rodzaje użytkowników: klienci, pracownicy i administratorzy.</div>
        <div>Logowanie się użytkowników do aplikacji daje pewność, że każdy z nich wysyła wiadomości we własnym imieniu.</div>
        <div>Klienci mogą prowadzić konwersację z pracownikiem, który jest zalogowany (on-line) lub z każdym pozostałym pracownikiem.</div>
        <div>Wysyłane i otrzymywane wiadomości są na bieżąco wyświetlane i zapisywane w bazie danych, tak aby można było z nich korzystać po następnym zalogowaniu.</div>`,
        descriptionMoreHeader: 'Więcej',
        descriptionMoreContent: `<div>Pracownicy mogą konwersować z zalogowanymi klientami (on-line) lub nie zalogowanymi (off-line).
        Całość korespondencji jest zapisywana do bazy danych i pracownicy mają pełny dostęp do historii swoich wiadomości.
        Pracownicy mają także opcję wysyłania wiadomości do wszystkich klientów jednocześnie niezależnie czy klienci są zalogowani lub off-line.
        Administratorzy mają możliwość przeglądania korespondencji każdego użytkownika.
        Ponadto administratorzy mają możliwość usuwania całości korespondencji użytkownika lub usuwania konta użytkownika wraz z całą jego korespondencją.</div>
        <div>Aplikacja została przygotowana w dwóch językach: PL i EN - zmiana następuje przy zmianie języka przeglądarki. Istnieje możliwość dodania kolejnych języków.</div>
        <div>Projekt opracowany w technologi RWD (Flex Layout) i Java Spring. RWD zapewnia możliwość korzystania zarówno na komputerach stacjonarnych lub laptopach jaki na tabletach i telefon komórkowych.
        Technologia Java Spring zapewnia bezpieczne i szybkie działanie aplikacji.</div>
        <div>W celu przetestowania działania aplikacji w roli klienta, proszę o samodzielne przeprowadzenie rejestracji i zalogowanie się.
        Aby zalogować się jako inny użytkownik, proszę o przesłanie mailem informacji o rodzaju konta (roli) - zostanie wtedy założone nowe konto testowe a aplikacja automatycznie prześle emailem dane logowania.</div>`
      },
      baseapp: {
        name: 'Aplikacja bazowa',
        description: `<div>Aplikacja bazowa - posiada podstawowe moduły takie jak administracja (uprawnienia dostępu użytkowników), moduł plikowy (zapisywanie, przeglądanie i pobieranie plików), wysyłka emaili, harmonogram zadań (wyzwalacz czasowy), wielojęzyczność.</div>
        <div>Na tej bazie można rozwijać dowolne funkcjonalności dla prowadzenia biznesu lub instytucji.</div>
        <div>Istniejące moduły mogą być także rozwijane lub zmieniane. Wygląd/stylizacja komponentów aplikacji może być dowolnie edytowana.</div>
        <p>Po kliknięciu na „Wypróbuj” otworzy się aplikacja bazowa z przykładową implementację fragmentu działalności sklepu internetowego. Po kliknięciu „Pokaż foto” można przeglądać screeny poszczególnych funkcjonalności wraz z opisem.</p>
        <p>W celu przetestowania aplikacji mogę udostępnić dane logowania.</p>`,
        descriptionMoreHeader: 'Więcej',
        descriptionMoreContent: 'More content',
        carouselHeader: 'Aplikacja bazowa',
        carouselDescription1: 'Panel użytkowników. Zakładka z możliwością edycji danych poszczególnych użytkowników.',
        carouselDescription2: 'Zakładka z opcją przypisywania ról do użytkownika.',
        carouselDescription3: 'Zakładka z opcją przypisywania uprawnień dostępu do wybranej roli użytkownika.',
        carouselDescription4: 'Opcja resetu hasła. Nowe hasło jest generowane randomowo i wysyłane przez system mailem bezpośrednio do użytkownika (admin nie zna nowego hasła)',
        carouselDescription5: 'Widok panelu z 2 zakładkami. W drugiej zakładce tabela produktów z możliwością podglądu zdjęcia produktu i edycji danych w osobnym oknie dialogowym.',
        carouselDescription6: 'Wyświetlanie pliku graficznego w osobnym oknie',
        carouselDescription7: 'Wyświetlanie pliku PDF w osobnym oknie z wieloma opcjami edycji',
        carouselDescription8: 'Przykład filtrowania tabeli przez słowo \"zam\". Możliwość filtrowania wg kalendarza a także opcja rozwijania wierszy tabeli.'
      }
    },
    contactus: {
      firm: 'Usługi programistyczne',
      phone: 'Tel.: ',
      form: 'Lub wypełnij poniższy formularz i wyślij:',
      subject: 'Temat wiadomości:',
      text: 'Tekst wiadomości:',
      replyMail: 'Email zwrotny:',
      reCaptcha: 'Weryfikacja reCaptcha:',
      successSubject: 'Sukces!',
      confirmSentMail: 'Twoja wiadomość została wysłana z powodzeniem.'
    },
    customerPanel: {
      changePassword: 'Zmień hasło do email',
      usernameOrEmail: 'Nazwa użytkownika lub email:',
      currentPassword: 'Obecne hasło:',
      newPassword: 'Nowe hasło:',
      repeatNewPassword: 'Powtórz nowe hasło:',
      confirmChangePassword: 'Hasło zostało zmienione.'
    },
    button: {
      services: 'Usługi',
      projects: 'Projekty',
      contactus: 'Kontakt',
      sendEmail: 'Wyślij email',
      test: 'Wypróbuj',
      readMore: 'czytaj więcej/mniej',
      customerPanel: 'Panel klienta',
      changePassword: 'Zmień hasło',
      showPhoto: 'Pokaż foto'
    },
    error: {
      reCaptcha: 'Weryfikacja reCaptcha jest niezbędna!',
      subject: 'Niepowodzenie!',
      validSubject: 'Minimum 2 a max 75 znaków bez znaków specjalnych',
      validMessage: 'Minimum 2 a max 400 znaków bez znaków specjalnych',
      validReplyEmail: 'Nieprawidłowy email',
      validUsername: 'Minimum 2 a max 40 znaków - dopuszczalne litery alfabetu angielskiego, liczby i podkreślnik.',
      validPassword: 'Minimum 2 a max 40 znaków ',
      differentPasswords: 'Oba nowe hasła nie są takie same!',
      no_connect: 'Błąd połączenia z serwerem. Sprawdź połączenie z internetem.',
      err_400: 'Błąd żądania HTTP.',
      err_401: 'Login lub hasło są niepoprawne.',
      err_403: 'Nie masz uprawnień do wykonania tej operacji.',
      err_404: 'Zasób nie został odnaleziony.',
      err_500: 'Wewnętrzny błąd serwera. Proszę skontaktuj się z administratorem.'
    }
  };
}
