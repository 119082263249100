import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-certyficate',
  templateUrl: './certyficate.component.html',
  styleUrls: ['./certyficate.component.scss']
})
export class CertyficateComponent implements OnInit {

  pdfSrc = '/assets/pdf/certificate.pdf';

  constructor() { }

  ngOnInit(): void {
  }

}
