import {Component, OnInit} from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from './language.service';
import { Translate } from './translate';
import {Message, MessageService} from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'aticode';
  translate: Translate;
  aticodePolicy: string;
  cookieMessage: Message[];

  constructor(private cookieService: CookieService, private languageService: LanguageService) {
    this.translate = this.languageService.loadLanguage();
    this.aticodePolicy = 'aticode.pl private policy';
  }

  ngOnInit(): void {
    if (!this.cookieService.check('cookie_law')){
      this.cookieMessage = [
        {severity: 'info', detail: this.translate.home.cookiesMessage}
      ];
    }
  }
  setCookie(): void {
    this.cookieService.set('cookie_law', this.aticodePolicy, 365, '/');
  }
}
