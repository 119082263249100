<h3>{{translate.contactus.firm}}</h3>
<div><b>{{translate.contactus.phone}}+48 730-285-980</b></div>
<div><b>Email: <a href="mailto:kontakt@aticode.pl">kontakt@aticode.pl</a></b></div>
<br/>
<div class="block">
    <form [formGroup]="sendEmailForm" (ngSubmit)="sendEmail()" novalidate>
        <label for="subject" class="block col-12 sm:col-8 md:col-6 lg:col-4">{{translate.contactus.subject}}</label>
        <input pInputText formControlName="subject" type="text" id="subject" class="block col-12 sm:col-8 md:col-6 lg:col-4" [pattern]="patterns.subject"
               [class.field-invalid]="sendEmailForm.get('subject').invalid && sendEmailForm.get('subject').touched">
        <p-message severity="error" text="{{translate.error.validSubject}}"
                   *ngIf="sendEmailForm.get('subject').invalid && sendEmailForm.get('subject').touched"
                   styleClass="message-text"></p-message>

        <label for="message" class="block col-12 sm:col-8 md:col-6 lg:col-4">{{translate.contactus.text}}</label>
        <textarea pInputTextarea formControlName="message" [pattern]="patterns.description" name="message" id="message" rows="5"
                  required [class.field-invalid]="sendEmailForm.get('message').invalid && sendEmailForm.get('message').touched"
                  class="block col-12 sm:col-8 md:col-6 lg:col-4"></textarea>
        <p-message severity="error" text="{{translate.error.validMessage}}"
                   *ngIf="sendEmailForm.get('message').invalid && sendEmailForm.get('message').touched"
                   styleClass="message-text"></p-message>

        <label for="replyEmail" class="block col-12 sm:col-8 md:col-6 lg:col-4">{{translate.contactus.replyMail}}</label>
        <input pInputText formControlName="replyEmail" type="text" id="replyEmail" class="block col-12 sm:col-8 md:col-6 lg:col-4" [pattern]="patterns.email"
               [class.field-invalid]="sendEmailForm.get('replyEmail').invalid && sendEmailForm.get('replyEmail').touched">
        <p-message severity="error" text="{{translate.error.validReplyEmail}}"
                   *ngIf="sendEmailForm.get('replyEmail').invalid && sendEmailForm.get('replyEmail').touched"
                   styleClass="message-text"></p-message>

        <label for="recaptcha" class="block col-12 sm:col-8 md:col-6 lg:col-4">{{translate.contactus.reCaptcha}}</label>
        <re-captcha id="recaptcha" (resolved)="resolvedReCaptcha($event)" name="captcha" siteKey="6LeiecsUAAAAAHR5QgJYzhQsMsQKdzTQCQv5wV4w"></re-captcha>
        <div class="flex col-12 sm:col-8 md:col-6 lg:col-4 justify-content-end">
            <input pButton [disabled]="sendEmailForm.invalid || !reCaptchaVerify"
                   type="submit"
                   value="{{translate.button.sendEmail}}"
                   class="p-button-raised">
        </div>
    </form>
</div>
<p-toast position="top-center"></p-toast>
