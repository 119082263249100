import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ChangePasswordData } from '../change-password-data';
import { LanguageService } from '../language.service';
import { Patterns } from '../patterns';
import { ServerAnswer } from '../server-answer';
import { Translate } from '../translate';
import { UserService } from '../user.service';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-customer-panel',
  templateUrl: './customer-panel.component.html',
  styleUrls: ['./customer-panel.component.scss']
})
export class CustomerPanelComponent implements OnInit {

  translate: Translate;
  patterns: Patterns;
  answer: ServerAnswer;
  changePasswordData: ChangePasswordData;
  changePasswordForm: FormGroup;
  reCaptchaVerify = false;
  reCaptchaResponse: string;

  constructor(private languageService: LanguageService, private userService: UserService, private messageService: MessageService) {
    this.translate = this.languageService.loadLanguage();
    this.answer = new ServerAnswer();
    this.patterns = new Patterns();
    this.changePasswordForm = new FormGroup({
      usernameOrEmail: new FormControl('', [Validators.required, Validators.pattern(this.patterns.email)]),
      currentPassword: new FormControl('', [Validators.required, Validators.pattern(this.patterns.password)]),
      newPassword: new FormControl('', [Validators.required, Validators.pattern(this.patterns.password)]),
      repeatNewPassword: new FormControl('', [Validators.required, Validators.pattern(this.patterns.password)])
    });
  }

  ngOnInit(): void {
  }

  changePassword(): void {
    const usernameOrEmail = this.changePasswordForm.get('usernameOrEmail').value;
    const currentPassword = this.changePasswordForm.get('currentPassword').value;
    const newPassword = this.changePasswordForm.get('newPassword').value;
    const repeatNewPassword = this.changePasswordForm.get('repeatNewPassword').value;
    if (newPassword === repeatNewPassword) {
      this.changePasswordData = new ChangePasswordData(usernameOrEmail, currentPassword, newPassword);
      this.userService.changeUserPassword(this.changePasswordData, this.reCaptchaResponse).subscribe(
      (response: HttpResponse<any>) => {
        this.answer = this.userService.switchAnswer(response.body);
        this.onChangedPassword();
      },
      (error: HttpErrorResponse) => {
        this.answer = this.userService.switchAnswer(error.status);
        this.onErrorServerResponse();
      });
    }
    if (!this.changePasswordForm.valid) {
      this.changePasswordForm.markAllAsTouched();
    }
  }
  resolvedReCaptcha(captchaResponse: string): void {
    this.reCaptchaResponse = captchaResponse;
    this.reCaptchaVerify = true;
  }
  onChangedPassword(): void {
    this.changePasswordForm.reset();
    this.answer.serverAnswer = null;
    this.reCaptchaVerify = false;
    this.messageService.add({severity: 'success', summary: this.answer.subject, detail: this.answer.message, life: 10000});
  }
  onErrorServerResponse(): void {
    this.changePasswordForm.reset();
    this.answer.serverAnswer = null;
    this.messageService.add({severity: 'error', summary: this.answer.subject, detail: this.answer.message, life: 10000});
  }
}
