import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../language.service';
import { Translate } from '../translate';
import { MenuItem } from 'primeng/api';
import { TabMenu } from 'primeng/tabmenu';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  translate: Translate;
  items: Array<MenuItem>;
  activeItem: MenuItem;

  constructor(private languageService: LanguageService) {
    this.translate = this.languageService.loadLanguage();
   }

  ngOnInit(): void {
    this.items = [
      { label: this.languageService.translate.button.services, icon: 'pi pi-fw pi-home', url: '/' },
      { label: this.languageService.translate.button.projects, icon: 'pi pi-fw pi-wallet', url: '/projects' },
      { label: this.languageService.translate.button.contactus, icon: 'pi pi-fw pi-phone', url: '/contactus' },
      { label: this.languageService.translate.button.customerPanel, icon: 'pi pi-fw pi-desktop', url: '/customerpanel' }
    ];
    const menuItems = this.items.filter(item => item.url === sessionStorage.getItem('activeNavbarUrl'));
    this.activeItem = menuItems.length === 1 ? menuItems[0] : this.activeItem;
  }
  onActiveItemChange(tabMenu: TabMenu): void {
    sessionStorage.setItem('activeNavbarUrl', tabMenu.activeItem.url);
  }
  onActiveLogo(): void {
    sessionStorage.setItem('activeNavbarUrl', '/');
    this.ngOnInit();
  }
}
