import { Injectable } from '@angular/core';
import { Translate } from './translate';
import { TranslateService } from '@ngx-translate/core';
import { getTranslateEn, getTranslatePl } from 'src/app/translate-resources';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  translate: Translate;
  serveLanguages: Array<string>;

  constructor(private translateService: TranslateService) {
    this.translate = this.loadLanguage();
    this.serveLanguages = ['pl', 'en'];
   }

  loadLanguage(): Translate {
    const browserLang = this.translateService.getBrowserLang();
    if (browserLang === 'pl') {
      return getTranslatePl();
    } else {
      return getTranslateEn();
    }
  }

}
