export class Patterns {
  static readonly SERVICES_PATH = '/services';
  static readonly PROJECTS_PATH = '/projects';
  // baseUrl = 'http://localhost:8080';
  baseUrl = 'https://aticode.pl:8445';
  subject = '^[^`#$%\^*+={\\[\\]}|\\\\:"\';<>]{2,75}$';
  description = '^[^`#$%\^*+={\\[\\]}|\\\\:"\';<>]{2,400}$';
  email = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  username = '^[a-zA-Z0-9_.-]{3,40}$';
  password = '^.{4,40}$';
}
