import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../language.service';
import { Translate } from '../translate';
import {BaseAppScreen} from '../base-app-screen';
import {UserService} from '../user.service';
import {Patterns} from '../patterns';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  translate: Translate;
  baseAppDialog = false;
  baseAppScreenList: Array<BaseAppScreen>;

  constructor(private languageService: LanguageService, private userService: UserService) {
    this.translate = this.languageService.loadLanguage();
  }

  ngOnInit(): void {
    this.baseAppScreenList = [
      { description: this.languageService.translate.projects.baseapp.carouselDescription1, screen: 'screen-1.png' },
      { description: this.languageService.translate.projects.baseapp.carouselDescription2, screen: 'screen-2.png' },
      { description: this.languageService.translate.projects.baseapp.carouselDescription3, screen: 'screen-3.png' },
      { description: this.languageService.translate.projects.baseapp.carouselDescription4, screen: 'screen-4.png' },
      { description: this.languageService.translate.projects.baseapp.carouselDescription5, screen: 'screen-5.png' },
      { description: this.languageService.translate.projects.baseapp.carouselDescription6, screen: 'screen-6.png' },
      { description: this.languageService.translate.projects.baseapp.carouselDescription7, screen: 'screen-7.png' },
      { description: this.languageService.translate.projects.baseapp.carouselDescription8, screen: 'screen-8.png' }
    ];
    this.userService.logVisit(Patterns.PROJECTS_PATH);
  }

  showBaseAppDialog(): void {
    this.baseAppDialog = true;
  }
}
