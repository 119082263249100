import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {ContactUs} from './contact-us';
import {LanguageService} from './language.service';
import {Patterns} from './patterns';
import {ServerAnswer} from './server-answer';
import {Translate} from './translate';
import {ChangePasswordData} from './change-password-data';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private answer: ServerAnswer;
  private patterns: Patterns;
  private translate: Translate;

  constructor(private http: HttpClient, private languageService: LanguageService, private translateService: TranslateService) {
    this.patterns = new Patterns();
    this.translate = this.languageService.loadLanguage();
    this.answer = new ServerAnswer();
  }

  sendEmailByContactUs(contactUs: ContactUs, captchaResponse: string): Observable<any> {
    contactUs.locale = this.translateService.getBrowserLang();
    const url = this.patterns.baseUrl + '/sendEmailByContactUs';
    const headers = new HttpHeaders({
      reCaptcha: captchaResponse
    });
    return this.http.post(url, contactUs, { headers, responseType: 'text', observe: 'response' });
  }
  changeUserPassword(changePasswordData: ChangePasswordData, captchaResponse: string): Observable<any> {
    const url = this.patterns.baseUrl + '/changeUserPassword';
    const headers = new HttpHeaders({
      reCaptcha: captchaResponse
    });
    return this.http.post(url, changePasswordData, { headers, responseType: 'text', observe: 'response' });
  }
  logVisit(path: string): void {
    this.http.get('https://jsonip.com').subscribe(
        (ipResponse: any) => {
          const url = this.patterns.baseUrl + path;
          this.http.get(url, {responseType: 'text'}).subscribe(
              (tokenResponse: any) => {
                const headers = new HttpHeaders({
                  Authorization: tokenResponse
                });
                this.http.post(url, ipResponse.ip, {headers, responseType: 'text', observe: 'response'}).subscribe(
                    () => {
                    },
                    (err: HttpErrorResponse) => {
                      console.log('SERVER_ERROR', err.message);
                    }
                );
              },
              (error: HttpErrorResponse) => {
                console.log('GET_TOKEN_ERROR', error.message);
              }
          );
        },
        (error: HttpErrorResponse) => {
          console.log('GET_IP_ERROR', error.message);
        }
    );
  }
  switchAnswer(answer: any): ServerAnswer {
    switch (answer) {
      case 'SENT_EMAIL':
        this.answer.serverAnswer = answer;
        this.answer.subject = this.translate.contactus.successSubject;
        this.answer.message = this.translate.contactus.confirmSentMail;
        break;
      case 'PASSWORD_CHANGED':
        this.answer.serverAnswer = answer;
        this.answer.subject = this.translate.contactus.successSubject;
        this.answer.message = this.translate.customerPanel.confirmChangePassword;
        break;
      case 'RECAPTCHA_FAIL':
        this.answer.serverAnswer = answer;
        this.answer.subject = this.translate.error.subject;
        this.answer.message = this.translate.error.reCaptcha;
        break;
      case 0:
        this.answer.errorStatus = answer;
        this.answer.subject = this.translate.error.subject;
        this.answer.message = this.translate.error.no_connect;
        break;
      case 400:
        this.answer.errorStatus = answer;
        this.answer.subject = this.translate.error.subject;
        this.answer.message = this.translate.error.err_400;
        break;
      case 401:
        this.answer.errorStatus = answer;
        this.answer.subject = this.translate.error.subject;
        this.answer.message = this.translate.error.err_401;
        break;
      case 403:
        this.answer.errorStatus = answer;
        this.answer.subject = this.translate.error.subject;
        this.answer.message = this.translate.error.err_403;
        break;
      case 404:
        this.answer.errorStatus = answer;
        this.answer.subject = this.translate.error.subject;
        this.answer.message = this.translate.error.err_404;
        break;
      default:
        if (answer >= 500) {
          this.answer.errorStatus = answer;
          this.answer.subject = this.translate.error.subject;
          this.answer.message = this.translate.error.err_500;
        }
        break;
    }
    return this.answer;
  }
}
