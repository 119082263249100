<p-tabView styleClass="tab-panel-card">
  <p-tabPanel header="{{translate.projects.medicalclinic.name}}">
    <div [innerHTML]="translate.projects.medicalclinic.description"></div>
    <a target="_blank" href="https://medicalclinic.aticode.pl">
      <p-button label="{{translate.button.test}}" styleClass="p-button-secondary p-button-rounded" icon="pi pi-check" iconPos="right"></p-button>
    </a>
    <br><br>
    <p-accordion styleClass="accordion-card">
      <p-accordionTab header="{{translate.projects.medicalclinic.descriptionMoreHeader}}">
        <div [innerHTML]="translate.projects.medicalclinic.descriptionMoreContent"></div>
      </p-accordionTab>
    </p-accordion>
  </p-tabPanel>
  <p-tabPanel header="{{translate.projects.civilworkoffers.name}}">
    <div [innerHTML]="translate.projects.civilworkoffers.description"></div>
    <a target="_blank" href="https://civilworkoffers.aticode.pl">
      <p-button label="{{translate.button.test}}" styleClass="p-button-secondary p-button-rounded" icon="pi pi-check" iconPos="right"></p-button>
    </a>
    <br><br>
    <p-accordion styleClass="accordion-card">
      <p-accordionTab header="{{translate.projects.civilworkoffers.descriptionMoreHeader}}">
        <div [innerHTML]="translate.projects.civilworkoffers.descriptionMoreContent"></div>
      </p-accordionTab>
    </p-accordion>
  </p-tabPanel>
  <p-tabPanel header="{{translate.projects.chat.name}}">
    <div [innerHTML]="translate.projects.chat.description"></div>
    <br>
    <a target="_blank" href="https://chat.aticode.pl">
      <p-button label="{{translate.button.test}}" styleClass="p-button-secondary p-button-rounded" icon="pi pi-check" iconPos="right"></p-button>
    </a>
    <br><br>
    <p-accordion styleClass="accordion-card">
      <p-accordionTab header="{{translate.projects.chat.descriptionMoreHeader}}">
        <div [innerHTML]="translate.projects.chat.descriptionMoreContent"></div>
      </p-accordionTab>
    </p-accordion>
  </p-tabPanel>
  <p-tabPanel header="{{translate.projects.baseapp.name}}">
    <div [innerHTML]="translate.projects.baseapp.description"></div>
    <br>
    <div class="grid">
      <div class="col-12 md:col-4 lg:col-3">
        <a target="_blank" href="https://shop.aticode.pl/index">
          <p-button label="{{translate.button.test}}" styleClass="p-button-secondary p-button-rounded" icon="pi pi-check" iconPos="right"></p-button>
        </a>
      </div>
      <div class="col-12 md:col-4 lg:col-3">
        <p-button (click)="showBaseAppDialog()" icon="pi pi-external-link" label="{{translate.button.showPhoto}}"></p-button>
      </div>
    </div>
  </p-tabPanel>
</p-tabView>

<p-dialog header="{{translate.projects.baseapp.carouselHeader}}" [(visible)]="baseAppDialog" [modal]="true" styleClass="base-app-dialog" [baseZIndex]="10000"
          [draggable]="false" [resizable]="false">
  <p-carousel [value]="baseAppScreenList" [numVisible]="1" [numScroll]="1" [circular]="false" [autoplayInterval]="10000" styleClass="carousel">
    <ng-template let-baseAppScreen pTemplate="item">
      <p>{{baseAppScreen.description}}</p>
      <img src="assets/img/{{baseAppScreen.screen}}" [alt]="baseAppScreen.name" width="100%" />
    </ng-template>
  </p-carousel>
</p-dialog>
