<p-accordion styleClass="accordion-card">
    <p-accordionTab header="{{translate.customerPanel.changePassword}}">
        <div class="block">
            <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()" novalidate>
                <label for="usernameOrEmail" class="block col-12 sm:col-8 md:col-6 lg:col-4">{{translate.customerPanel.usernameOrEmail}}</label>
                <input pInputText formControlName="usernameOrEmail" type="text" id="usernameOrEmail" class="block col-12 sm:col-8 md:col-6 lg:col-4" autocomplete="off"
                       [class.field-invalid]="changePasswordForm.get('usernameOrEmail').invalid && changePasswordForm.get('usernameOrEmail').touched">
                <p-message severity="error" text="{{translate.error.validUsername}}"
                           *ngIf="changePasswordForm.get('usernameOrEmail').invalid && changePasswordForm.get('usernameOrEmail').touched"
                           styleClass="message-text"></p-message>

                <label for="currentPassword" class="block col-12 sm:col-8 md:col-6 lg:col-4">{{translate.customerPanel.currentPassword}}</label>
                <input pPassword formControlName="currentPassword" type="password" id="currentPassword" class="block col-12 sm:col-8 md:col-6 lg:col-4" autocomplete="off"
                       [class.field-invalid]="changePasswordForm.get('currentPassword').invalid && changePasswordForm.get('currentPassword').touched">
                <p-message severity="error" text="{{translate.error.validPassword}}"
                           *ngIf="changePasswordForm.get('currentPassword').invalid && changePasswordForm.get('currentPassword').touched"
                           styleClass="message-text"></p-message>

                <label for="newPassword" class="block col-12 sm:col-8 md:col-6 lg:col-4">{{translate.customerPanel.newPassword}}</label>
                <input pPassword formControlName="newPassword" type="password" id="newPassword" class="block col-12 sm:col-8 md:col-6 lg:col-4" autocomplete="off"
                       [class.field-invalid]="changePasswordForm.get('newPassword').invalid && changePasswordForm.get('newPassword').touched">
                <p-message severity="error" text="{{translate.error.validPassword}}"
                           *ngIf="changePasswordForm.get('newPassword').invalid && changePasswordForm.get('newPassword').touched"
                           styleClass="message-text"></p-message>

                <label for="repeatNewPassword" class="block col-12 sm:col-8 md:col-6 lg:col-4">{{translate.customerPanel.repeatNewPassword}}</label>
                <input pPassword formControlName="repeatNewPassword" type="password" id="repeatNewPassword" class="block col-12 sm:col-8 md:col-6 lg:col-4" autocomplete="off"
                       [class.field-invalid]="changePasswordForm.get('repeatNewPassword').invalid && changePasswordForm.get('repeatNewPassword').touched">
                <p-message severity="error" text="{{translate.error.validPassword}}"
                           *ngIf="changePasswordForm.get('repeatNewPassword').invalid && changePasswordForm.get('repeatNewPassword').touched"
                           styleClass="message-text"></p-message>
                <p-message severity="error" text="{{translate.error.differentPasswords}}"
                           *ngIf="changePasswordForm.get('newPassword').value !== changePasswordForm.get('repeatNewPassword').value"
                           styleClass="message-text"></p-message>

                <label for="recaptcha" class="block col-12 sm:col-8 md:col-6 lg:col-4">{{translate.contactus.reCaptcha}}</label>
                <re-captcha id="recaptcha"
                            (resolved)="resolvedReCaptcha($event)"
                            name="captcha" siteKey="6LeiecsUAAAAAHR5QgJYzhQsMsQKdzTQCQv5wV4w"></re-captcha>
                <div class="flex col-12 sm:col-8 md:col-6 lg:col-4 justify-content-end">
                    <input pButton [disabled]="changePasswordForm.invalid || !reCaptchaVerify ||
                          changePasswordForm.get('newPassword').value !== changePasswordForm.get('repeatNewPassword').value"
                           type="submit" value="{{translate.button.changePassword}}">
                </div>
            </form>
        </div>
    </p-accordionTab>
</p-accordion>
<p-toast position="top-center"></p-toast>
