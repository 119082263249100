import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ServicesComponent } from './services/services.component';
import { ProjectsComponent } from './projects/projects.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { NavbarComponent } from './navbar/navbar.component';
import { LanguageService } from './language.service';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { CurriculumVitaeComponent } from './curriculum-vitae/curriculum-vitae.component';
import { CertyficateComponent } from './certyficate/certyficate.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { RewerdComponent } from './rewerd/rewerd.component';
import { CustomerPanelComponent } from './customer-panel/customer-panel.component';
import { UserService } from './user.service';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { AccordionModule } from 'primeng/accordion';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {PasswordModule} from 'primeng/password';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {MessageModule} from 'primeng/message';
import {MessagesModule} from 'primeng/messages';
import {MessageService} from 'primeng/api';
import {DialogModule} from 'primeng/dialog';
import {CarouselModule} from 'primeng/carousel';
import {ToastModule} from 'primeng/toast';

@NgModule({
  declarations: [
    AppComponent,
    ServicesComponent,
    ProjectsComponent,
    ContactUsComponent,
    NavbarComponent,
    CurriculumVitaeComponent,
    CertyficateComponent,
    RewerdComponent,
    CustomerPanelComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    HttpClientModule,
    PdfViewerModule,
    TranslateModule.forRoot(),
    TabMenuModule,
    TabViewModule,
    AccordionModule,
    BrowserAnimationsModule,
    ButtonModule,
    InputTextModule,
    PasswordModule,
    InputTextareaModule,
    MessageModule,
    MessagesModule,
    DialogModule,
    CarouselModule,
    ToastModule
  ],
  providers: [LanguageService, UserService, CookieService, MessageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
