<div class="navbar-card grid">
  <div class="col-12 md:col-4 lg:col-2 align-self-center">
    <a routerLink="/">
      <img src="assets/img/logo.png" alt="logo" class="logo" width="190" (click)="onActiveLogo()">
    </a>
  </div>
  <div class="col-12 md:col-8 lg:col-10 flex justify-content-end align-self-center">
    <p-tabMenu #tabMenu [model]="items" [activeItem]="activeItem" (click)="onActiveItemChange(tabMenu)" styleClass="p-tabmenuitem"></p-tabMenu>
  </div>
</div>
